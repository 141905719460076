#treasury-dashboard-view {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  // width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }

  .rea-card {
    max-width: unset !important;
    width: 100%;
    margin: auto;
    max-height: auto;
    background-color: transparent !important;

    .box_wrapper {
      padding: 0 5em;
      margin-bottom: 5em;

      .button {
        border: 1px solid;
        box-sizing: border-box;
        border-radius: 72px;
        text-align: center;
        padding: 24px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .grid_item_box {
      padding: 24px;
      background: rgba(255, 255, 255, 0.35);
      box-shadow: 4px 16px 64px rgba(120, 58, 217, 0.08);
      backdrop-filter: blur(100px);
      border-radius: 40px;
    }

    .card-header {
      margin-bottom: 1rem;
    }
    &.rea-chart-card {
      .chart-card-header {
        padding: 0px 20px !important;
        min-width: 300px;
      }
      height: 355px;
    }
  }
  .hero-metrics {
    width: 100%;
    margin-bottom: 23px;
    text-align: center;
    .rea-card {
      max-width: unset !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      h5 {
        font-weight: bold;
      }
      div.metric {
        margin: 3px;
        min-width: 140px;
        width: 30%;
      }
    }
  }
  .data-grid {
    overflow: hidden;
  }
}

.smaller {
  .rea-card {
    width: 97%;
  }
  .hero-metrics .rea-card {
    > .MuiBox-root {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    div.metric {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 30% !important;
    max-width: 60%;
    text-align: left !important;
    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.very-small {
  .hero-metrics .rea-card {
    > .MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.center {
  text-align: center;
}
